const LoadingSpinner = () => {
  return (
    <div className="loading_spinner_wrapper">
      <div className="loading_spinner_img_wrapper">
        <img
          className="loading_spinner_img"
          src="https://platform.attaindata.ai/favicon-light.svg"
          alt=""
        />
      </div>
    </div>
  );
};

export default LoadingSpinner;
