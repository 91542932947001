import App from './App';
import ErrorMsg from 'ErrorMsg';
import GlobalStyle from 'styles/globalStyles';
// import React from 'react';
import SecureApolloProvider from '@klover/ksaas-common-web/services/SecureApolloProvider';
import { AuthProvider } from '@klover/ksaas-common-web/services/AuthProvider';
import {
  BrowserRouter,
  // createRoutesFromChildren,
  // matchRoutes,
  // useLocation,
  // useNavigationType,
} from 'react-router-dom';
import { CssBaseline } from '@klover/attain-design-system';
import { ErrorBoundary } from 'react-error-boundary';
import { createRoot } from 'react-dom/client';
// import * as Sentry from '@sentry/react';
import './index.css';
import 'react-day-picker/dist/style.css';

const container = document.getElementById('root');
const root = createRoot(container!);

// if (window.location.hostname !== 'localhost') {
//   Sentry.init({
//     dsn: import.meta.env.VITE_SENTRY_DSN,
//     environment: import.meta.env.VITE_SENTRY_ENV,
//     integrations: [
//       Sentry.reactRouterV6BrowserTracingIntegration({
//         useEffect: React.useEffect,
//         useLocation,
//         useNavigationType,
//         createRoutesFromChildren,
//         matchRoutes,
//       }),
//       Sentry.replayIntegration(),
//       Sentry.browserProfilingIntegration(),
//     ],
//     // Tracing
//     tracesSampleRate: 1.0,
//     tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
//     // Session Replay
//     replaysSessionSampleRate: 1.0,
//     replaysOnErrorSampleRate: 1.0, // Keep at 100% to capture sessions with errors
//     // Profiling
//     profilesSampleRate: 1.0,
//   });
// }

root.render(
  <BrowserRouter>
    <AuthProvider
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID as string}
      domain={import.meta.env.VITE_AUTH0_DOMAIN as string}
      authorizationParams={{
        audience: import.meta.env.VITE_AUTH0_AUDIENCE as string,
      }}
    >
      <SecureApolloProvider
        url={import.meta.env.DEV ? '' : import.meta.env.VITE_GRAPHQL_URL}
        ws={import.meta.env.VITE_GRAPHQL_URL_SUBSCRIPTION}
      >
        <CssBaseline />
        <GlobalStyle />
        <ErrorBoundary fallback={<ErrorMsg />}>
          <App />
        </ErrorBoundary>
      </SecureApolloProvider>
    </AuthProvider>
  </BrowserRouter>
);
